import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

function Page(props) {
  const { series, chapter, page: currentPage } = useParams();
  const [data, setData] = useState({});
  const [pageURL, setPageURL] = useState('');
  const [numberOfPages, setNumberOfPages] = useState(0);
  const navigate = useNavigate();
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipType, setTooltipType] = useState('');
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [imagePosition, setImagePosition] = useState({ x: 0, y: 0 });
  const imageRef = useRef(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(`https://deferredgalaxy.com/scripts/getPage.php?seriesId=${series}&chapterId=${chapter}`)
      .then(response => response.text())
      .then(text => JSON.parse(text))
      .then(data => {
        console.log (data);
        const pagesArray = data[0].pages.split('\n');
        const directoryPath = pagesArray.shift();
        const fullPageUrls = pagesArray.map(filename => `${directoryPath}${filename.trim()}`);
        setPageURL(fullPageUrls[currentPage - 1]);
        setNumberOfPages(fullPageUrls.length);
        if (data.length > 0) {
          setData(data[0]);
        }
      })
      .catch(error => console.error('Error:', error))
      .finally(() => setLoading(false));
  }, [series, chapter, currentPage]);

  const handleEllipsisClick = (type) => {
    setTooltipType(type);
    setTooltipVisible(!tooltipVisible);
  };

  const handleGoToPage = (e) => {
    if (e.key === 'Enter') {
      const page = parseInt(e.target.value);
      if (page >= 1 && page <= numberOfPages) {
        navigate(`/comics/${series}/${chapter}/${page}`);
        setTooltipVisible(false); // Close tooltip after navigation
      }
    }
  };

  const renderPageNumbers = () => {
    const pages = [];
    let startPage = Math.max(2, parseInt(currentPage) - 3);
    let endPage = Math.min(numberOfPages - 1, parseInt(currentPage) + 3);
    let buttonNumber = 0;

    if (numberOfPages > 11 && (endPage - startPage != 6 || currentPage == 5 || currentPage == numberOfPages - 4)) {
      if (startPage == 2) {
        endPage += (7 - (endPage - startPage));
      }
      if (endPage == numberOfPages - 1) {
        startPage -= (7 - (endPage - startPage));
      }
    }

    pages.push(
      <button key="1" className={currentPage == 1 && "current-page"} onClick={() => navigate(`/comics/${series}/${chapter}/1`)}>1</button>
    );

    if (startPage > 2) {
      if (startPage == 3) {
        pages.push(<button key="2" className={currentPage == 2 && "current-page"} onClick={() => navigate(`/comics/${series}/${chapter}/2`)}>2</button>);
      } else {
        pages.push(<button id="ellipsis" key="start-ellipsis" onClick={() => handleEllipsisClick('start')}>...</button>);
      }
    }


    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <button key={i} className={i == currentPage && "current-page"} onClick={() => navigate(`/comics/${series}/${chapter}/${i}`)}>{i}</button>
      );
    }

    if (endPage < numberOfPages - 1) {
      if (endPage == numberOfPages - 2) {
        pages.push(<button key={numberOfPages - 1} className={currentPage == numberOfPages - 1 ? "current-page" : ""} onClick={() => navigate(`/comics/${series}/${chapter}/${numberOfPages - 1}`)}>{numberOfPages - 1}</button>);
      } else {
        pages.push(<button id="ellipsis" key="end-ellipsis" onClick={() => handleEllipsisClick('end')}>...</button>);
      }
    }

    pages.push(
      <button key={numberOfPages} className={currentPage == numberOfPages && "current-page"}  onClick={() => navigate(`/comics/${series}/${chapter}/${numberOfPages}`)}>{numberOfPages}</button>
    );

    return pages;
  };

  const toggleFullscreen = () => {
    setIsFullscreen(!isFullscreen);
    setZoomLevel(1); // Reset zoom level when opening or closing fullscreen
  };

  const handleZoomIn = () => {
    setZoomLevel(zoomLevel => zoomLevel + 0.2);
  };

  const handleZoomOut = () => {
    setZoomLevel(zoomLevel => Math.max(1, zoomLevel - 0.2));
  };

  const handleMouseUp = () => {
    if (isDragging) {
      setIsDragging(false);
    }
  };
  
  const handleMouseMove = (e) => {
    if (isDragging) {
      const dx = e.clientX - dragStart.x;
      const dy = e.clientY - dragStart.y;
      setImagePosition({
        x: imagePosition.x + dx,
        y: imagePosition.y + dy,
      });
      setDragStart({
        x: e.clientX,
        y: e.clientY,
      });
    }
  };

  const handleMouseDown = (e) => {
    e.preventDefault(); // This prevents the default drag behavior for images
    setIsDragging(true);
    setDragStart({
      x: e.clientX,
      y: e.clientY,
    });
  };

  const handleWheel = (e) => {
    e.preventDefault();
    if (e.deltaY < 0) {
      handleZoomIn();
    } else if (e.deltaY > 0) {
      handleZoomOut();
    }
  };

  useEffect(() => {
    if (!isFullscreen) {
      setImagePosition({ x: 0, y: 0 });
    }
  }, [isFullscreen, currentPage]);

  const handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      toggleFullscreen();
    }

    if (event.key === 'ArrowLeft' && currentPage > 1) {
      navigate(`/comics/${series}/${chapter}/${Math.max(1, parseInt(currentPage) - 1)}`);
    } else if (event.key === 'ArrowRight' && currentPage < numberOfPages) {
      navigate(`/comics/${series}/${chapter}/${Math.min(numberOfPages, parseInt(currentPage) + 1)}`);
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [currentPage, numberOfPages, navigate]);


  if (loading) {
    return <div class="loading">
      <svg xmlns="http://www.w3.org/2000/svg" class="spinner" viewBox="0 0 50 50">
        <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
      </svg>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <h3>Loading...</h3>
      </div>;
  }


  return (
    <div className={`${props.className}`} onMouseUp={handleMouseUp}>
      <a href={data.section < 0 ? `/comics/${data.seriesId}` : `/comics/${data.seriesId}/${data.section}`}><div className='navigator' style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}><button className='long-button'>◄ Back to Chapters</button><p class="page-chapter-title">{data.title}</p></div></a>
      <div className="navigator">
        <div className='arrow-container'>
            <button style={{visibility: currentPage == 1 && "hidden"}} onClick={() => navigate(`/comics/${series}/${chapter}/${Math.max(1, parseInt(currentPage) - 1)}`)}>◄</button>
        </div>
        <div className='pages-container'>
          {renderPageNumbers()}
        </div>
        <div className='arrow-container'>
          <button style={{visibility: currentPage == numberOfPages && "hidden"}} onClick={() => navigate(`/comics/${series}/${chapter}/${Math.min(numberOfPages, parseInt(currentPage) + 1)}`)}>►</button>
        </div>
        {tooltipVisible && (
          <div className="tooltip" style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%) translateY(60px)', marginTop: '10px' }}>
            <input onKeyDown={handleGoToPage} placeholder="Go to page" autoFocus />
          </div>
        )}
      </div>

      {isFullscreen && (
        <div
          style={{
            display: 'flex', // Enables flexbox
            justifyContent: 'center', // Centers children horizontally
            alignItems: 'center', // Centers children vertically
            position: 'fixed',
            top: 0,
            left: 0,
            height: '100vh',
            width: '100vw',
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            zIndex: 1050,
            overflow: 'hidden', // Prevent scrolling the page background
          }}
          onWheel={handleWheel}
          onMouseMove={handleMouseMove}
          onMouseLeave={handleMouseUp} // Stop dragging when the mouse leaves the window
        >
        <div 
            onMouseDown={handleMouseDown}
            style={{
              cursor: isDragging ? 'grabbing' : 'grab',
              position: 'absolute',
              top: imagePosition.y,
              left: imagePosition.x,
              width: '100%',
              transform: `scale(${zoomLevel})`,
              transition: isDragging ? 'none' : 'transform 0.2s ease, top 0.2s ease, left 0.2s ease',
            }}>
          <img
            ref={imageRef}
            className="page"
            src={pageURL}
            alt={`Page ${currentPage}`}
            style={{
              cursor: isDragging ? 'grabbing' : 'grab',
              transition: isDragging ? 'none' : 'transform 0.2s ease, top 0.2s ease, left 0.2s ease',
            }}
          />
        </div>
    <button onClick={(e) => { e.stopPropagation(); handleZoomIn(); }} style={{
      position: 'absolute',
      top: '20px',
      right: '140px',
      fontSize: '24px',
      padding: '10px 20px',
      color: '#FFF',
      backgroundColor: '#306530',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer'
    }}>+</button>
    <button onClick={(e) => { e.stopPropagation(); handleZoomOut(); }} style={{
      position: 'absolute',
      top: '20px',
      right: '80px',
      fontSize: '24px',
      padding: '10px 20px',
      color: '#FFF',
      backgroundColor: '#306530',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer'
    }}>-</button>
    <button onClick={(e) => { e.stopPropagation(); toggleFullscreen (); }} style={{
      position: 'absolute',
      top: '20px',
      right: '20px',
      fontSize: '24px',
      padding: '10px 20px',
      color: '#FFF',
      backgroundColor: '#306530',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
      borderRadius: '100px'
    }}>X</button>
    </div>
  )}
  <img
    className="page"
    src={pageURL}
    alt={`Page ${currentPage}`}
    onClick={toggleFullscreen}
  />
    </div>
  );
}

export default Page;
