import React from 'react';
import { Navigate, redirect, useParams } from 'react-router-dom';

function Redirect (props) {
    let { series, chapter, page } = useParams ();
    let url = `/comics/${series}`

    if (chapter && page) {
        url += `/${chapter}/${page}`
    }

    return <Navigate to={url} replace />;
}


export default Redirect;