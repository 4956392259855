import logo from './logo.svg';
import './App.css';
import Navbar from './components/navbar';
import ComicList from './components/comic-list';
import { BrowserRouter, Navigate, Routes, Route, } from 'react-router-dom';
import Redirect from './components/redirect';
import Page from './components/page';
import Series from './components/series';
import Changelog from './components/changelog';
import PagesScrollView from './components/pages-scrollview';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <Navbar className="top-bar"></Navbar>
        <div className='App-header'>
          <Routes>
            <Route path="/" element={<Series />}/>
            <Route path="/comics" element={<Series />}/>
            <Route path="/comics/:series" element={<ComicList/>}/>
            <Route path="/comics/:series/:section" element={<ComicList/>}/>
            <Route path="/comics/:series/:chapter/:page" element={<Page />}/>
            <Route path="/comics/scrollview/:series/:chapter/" element={<PagesScrollView />}/>
            <Route path="/changelog" element={<Changelog />} />
            
            <Route path="/chapters/:series" element={<Redirect />} /> 
            <Route path="/pages/:series/:chapter/:page" element={<Redirect />} /> 
        </Routes>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
