import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

function Series (props) {
  const navigate = useNavigate ();
  const [comics, setComics] = useState ([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(`https://deferredgalaxy.com/scripts/getSeries.php`)
      .then(response => response.text()) // Get the response text
      .then(text => {
        return JSON.parse(text); // Parse it as JSON
      })
      .then (data => {
        setComics (data);
      })
      .catch(error => console.error('Error:', error))
      .finally(() => setLoading(false));
  }, []);

  if (loading) {
    return <div class="loading">
      <svg xmlns="http://www.w3.org/2000/svg" class="spinner" viewBox="0 0 50 50">
        <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
      </svg>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <h3>Loading...</h3>
      </div>;
  }

  return <div className={`${props.className}`}>
    <h3>Select a series</h3>
    <div className='series-list'>
       {comics.map ((series, index) => (
        <a href={`/comics/${series.alias}`}>
          <div className="series well">
            <img src={series.image} />
          </div>
        </a>
        ))}
    </div>
  </div>
}

export default Series;