import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

function ComicList (props) {
  const { series, section } = useParams ();
  const [chapters, setChapters] = useState([]);
  const [title, setTitle] = useState ('');
  const [viewPages, setViewPages] = useState("");
  const navigate = useNavigate ();
  const [loading, setLoading] = useState(true);
  const [currentSection, setCurrentSection] = useState(null);
  const [sectionDescription, setSectionDescription] = useState ("");
  const [currentSectionID, setCurrentSectionID] = useState(0);
  const [sections, setSections] = useState([]);
  const [viewList, setViewList] = useState(false);
  const [sectionPrevious, setSectionPrevious] = useState(null);
  const [sectionNext, setSectionNext] = useState (null);

 useEffect(() => {
  const fetchSections = async () => {
    const seriesId = series;
    setLoading (true);

    try {
      const response = await fetch (`https://deferredgalaxy.com/scripts/getSections.php?seriesId=${seriesId}`);
      const sectionsText = await response.text ();
      const sectionsData = JSON.parse (sectionsText);

      if (sectionsData.length > 0) {
        const filteredEntries = sectionsData.filter (data => data.description !== "DIVIDER");

        for (let i = 0; i < filteredEntries.length; i++) {
          if (i > 0) {
            filteredEntries [i].previous = filteredEntries [i - 1].id;
          }

          if (i < filteredEntries.length - 1) {
            filteredEntries [i].next = filteredEntries [i + 1].id;
          }
        }

        sectionsData.forEach(entry => {
          if (entry.description !== "DIVIDER") {
            const paginationData = filteredEntries.find (filteredEntry => filteredEntry.id === entry.id);
            entry.next = paginationData.next || null;
            entry.previous = paginationData.previous || null;
          }
        });
        setSections(sectionsData);

        if (section == null && sectionsData.length > 0) {
          const thisSection = sectionsData.find (section => section.description !== "DIVIDER");
          if (thisSection) {
            setCurrentSection (thisSection.name);
            setCurrentSectionID (thisSection.entry);
            setSectionPrevious (thisSection.previous);
            setSectionNext (thisSection.next);
            setSectionDescription (thisSection.description);
          }
        } else {
          const thisSection = sectionsData.find (sectionData => sectionData.entry == section);
          setCurrentSection (thisSection.name);
          setCurrentSectionID (thisSection.entry);
          setSectionPrevious (thisSection.previous);
          setSectionNext (thisSection.next);
          setSectionDescription (thisSection.description);
        }
      } else {
        setCurrentSectionID (-1);
      }
    } catch (error) {
      console.error ('Error:', error);
    }
  };

  fetchSections ();
}, [series, section]);

useEffect(() => {
  const fetchChapters = async () => {
    if (currentSectionID === 0) return;

    try {
      const chaptersResponseURL = `https://deferredgalaxy.com/scripts/getChapters.php?seriesId=${series}` + (currentSectionID > 0 ? `&section=${currentSectionID}`: ``);
      const chaptersResponse = await fetch(chaptersResponseURL);
      const chaptersText = await chaptersResponse.text();
      const chaptersData = JSON.parse(chaptersText);
      console.log (chaptersData);
      const updatedChapters = chaptersData.map(chapter => {
        const pagesArray = chapter.pages.split('\n');
        const directoryPath = pagesArray.shift();
        const fullPageUrls = pagesArray.map(filename => `${directoryPath}${filename.trim()}`);
        return { ...chapter, pages: fullPageUrls };
      });
      setChapters(updatedChapters);
      if (chaptersData.length > 0) {
        setTitle(chaptersData[0].series);
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  fetchChapters();
}, [series, currentSectionID]);

  if (loading) {
    return <div class="loading">
      <svg xmlns="http://www.w3.org/2000/svg" class="spinner" viewBox="0 0 50 50">
        <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
      </svg>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <h3>Loading...</h3>
      </div>;
  }

  return <div className={`${props.className}`}><div>
      <div className='chapters-list-header'>
        <div><img className='chapters-list-series-title' src={chapters [0].seriesImage}></img></div>
        {currentSectionID > 0 && (
          <>
        <span>
          <a href={`/comics/${series}/${sectionPrevious}`} className='button button-large' style={{visibility: sectionPrevious != null ? "" : "hidden"}}>◀︎</a>
          <div className="dropdown">
            <button onClick={() => setViewList (!viewList)} class="dropbtn">{currentSection} {viewList ? <span>▲</span> : <span>▼</span>}</button>
            <div style={{display: viewList ? "block" : "none"}} class="dropdown-content">
            {sections.map(section => (
              section.description === "DIVIDER" 
                ? <span>{section.name}</span>
                : <a href={currentSectionID != section.entry ? `/comics/${series}/${section.entry}` : "#"} className={currentSectionID == section.entry && "current"} onClick={() => currentSectionID != section.entry ? setViewList (false) : {}}>{section.name}</a>
            ))}
            </div>
          </div>
          <a href={`/comics/${series}/${sectionNext}`} className='button button-large' style={{visibility: sectionNext != null ? "" : "hidden"}}>▶︎</a>
        </span>
        <p className='section-description'>{sectionDescription}</p>
        </>
        )}
      </div>
      <div className='well chapter-list'>
          {chapters.map(chapter => (
            <div id={chapter.alias} key={chapter.id} className='chapter'>
              <a href={`/comics/${series}/${chapter.alias}/1`}>
                <div className='chapter-cover'><img src={chapter.image}></img></div>
              </a>
              <div className='chapter-info'>
                <a href={`/comics/${series}/${chapter.alias}/1`}>
                  <div className='chapter-banner'>
                    <div className='chapter-number'>{chapter.entry}</div>
                    <div className='chapter-title'><div>{chapter.title}</div></div>
                  </div>
                </a>
                <div className='chapter-description'>
                  <p className='chapter-release-date'>{chapter.datepublished}</p>
                  <p>{chapter.description}</p>
                  <a href={`/comics/${series}/${chapter.alias}/1`}><div className='button'>Open in Page View</div></a>
                  <a href={`/comics/scrollview/${series}/${chapter.alias}/`}><div className='button'>Open in Scroll View</div></a>
                  <div className='button' onClick={() => setViewPages(viewPages !== chapter.id ? chapter.id: "")}>{viewPages !== chapter.id ? "View Page Thumbnails" : "Hide Page Thumbnails"}</div>
                  { viewPages === chapter.id && (
                  <div className='chapter-pages'>
                    {chapter.pages.map((page, index) => (
                      <div className='chapter-page'><a href={`/comics/${series}/${chapter.alias}/${index + 1}`}><img key={index} src={page} alt={`Page ${index + 1} of ${chapter.title}`} className="comic-page-preview" /></a></div>
                    ))}
                  </div>
                  )}
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  </div>;
}

export default ComicList;