import React from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import changelogData from '../changelog.json';

function Changelog(props) {
  return (
    <>
      {changelogData.logs.map((log, index) => (
        <div key={index}>
          <p><b>{log.date}</b></p>
            {log.notes.split('\n').map((line, index) => (
              <React.Fragment key={index}>
                <p style={{margin: "10px 0px", lineHeight: "21px", fontSize: "20px" }}>{line}</p>
              </React.Fragment>
            ))}
        </div>
      ))}
    </>
  );
}

export default Changelog;
