import React from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import youtube from '../svg/youtube.svg';
import twitter from '../svg/twitter.svg';
import twitch from '../svg/twitch.svg';
import discord from '../svg/discord.svg';
import patreon from '../svg/patreon.svg';

function Navbar(props) {
  const navigate = useNavigate();
  const location = useLocation();

  const isActive = (path) => {
    if (path === '/') {
      return location.pathname === '/';
    }
    return location.pathname.startsWith(path);
  };

  return (
    <div className={`${props.className}`}>
      <div className='site-title' onClick={() => navigate('/')}>
        <a href='/'><h1>Deferred Galaxy</h1></a>
      </div>
      <div className="well" style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
        <ul className="nav menu nav-pills nav-stacked mod-list">
          {/* <li className={`default current ${isActive('/') ? 'active' : ''}`} onClick={() => navigate('/')}>Home</li> */}
          <li className={`${isActive('/comics/') ? 'active' : ''}`} onClick={() => navigate('/comics/')}><a href="/comics/">Comics</a></li>
          <li className={`${isActive('/changelog/') ? 'active' : ''}`} onClick={() => navigate('/changelog/')}><a href="/changelog/">Changelog</a></li>
          {/* <li className={`${isActive('/lore/') ? 'active' : ''}`} onClick={() => navigate('/lore/')}>Lore</li> */}
          
        </ul>
        <div className='links'>
          <a href='https://www.youtube.com/channel/UCHLPIx1YZlxxY0W6NvmwHag' target="_blank"><img src={youtube} /></a>
          <a href='https://twitter.com/NateWittles' target="_blank"><img src={twitter} /></a>
          <a href='https://www.twitch.tv/natewittles' target="_blank"><img src={twitch} /></a>
          <a href='https://discord.gg/DgtPdW6' target="_blank"><img src={discord} /></a>
          <a href='https://www.patreon.com/DeferredGalaxy' target="_blank"><img src={patreon} /></a>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
