import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

function PagesScrollView(props) {
  const { series, chapter, page: currentPage } = useParams();
  const [pages, setPages] = useState([]);
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [imageLoadingStatus, setImageLoadingStatus] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetch(`https://deferredgalaxy.com/scripts/getPage.php?seriesId=${series}&chapterId=${chapter}`)
      .then(response => response.text())
      .then(text => JSON.parse(text))
      .then(data => {
        const pagesArray = data[0].pages.split('\n');
        const directoryPath = pagesArray.shift();
        const fullPageUrls = pagesArray.map(filename => `${directoryPath}${filename.trim()}`);
        setPages(fullPageUrls);
        setData(data[0]);
        setImageLoadingStatus(new Array(fullPageUrls.length).fill(true)); // Initialize loading status as true for all images
      })
      .catch(error => console.error('Error:', error))
      .finally(() => setLoading(false));
  }, [series, chapter, currentPage]);

  const handleImageLoad = (index) => {
    const newStatus = [...imageLoadingStatus];
    newStatus[index] = false;
    setImageLoadingStatus(newStatus);
  };

  if (loading) {
    return <div class="loading">
    <svg xmlns="http://www.w3.org/2000/svg" class="spinner" viewBox="0 0 50 50">
      <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
    </svg>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <h3>Loading...</h3>
    </div>;
  }

  return (
    <div>
      <div className='navigator'>
      <a href={data.section < 0 ? `/comics/${data.seriesId}` : `/comics/${data.seriesId}/${data.section}`}><button className='long-button'>◄ Back to Chapters</button></a>
        <p className="page-chapter-title">{data.title}</p>
      </div>
      {pages.map((page, index) => (
        <img
          key={`image-${index}`}
          src={page}
          alt={`page ${index + 1} of ${pages.length}`}
          className='page-scrollView'
          onLoad={(event) => event.target.classList.add('loaded')}
        />
    ))}

    
    </div>
  );
}

export default PagesScrollView;
